
import {defineComponent, onMounted, reactive, toRefs} from 'vue';
import {Form, Field, ErrorMessage} from 'vee-validate';
import identity from '@/api/identity';
import {useRoute} from 'vue-router';

interface MFA {
    username: string;
    qr?: string;
}

export default defineComponent({
    name: 'MFA',
    components: {
        Form,
        Field,
        ErrorMessage
    },
    setup ()
    {
        const route = useRoute();
        const applicationId = route.params.id.toString();

        const data = reactive({
            mfa: {} as MFA,
            mfaError: false,
            mfaReset: false,
            token: '',
            showReset: false,
            loadingMFA: false,
            error: 0
        });

        async function verify ()
        {
            if (!data.loadingMFA)
            {
                try
                {
                    data.error = 0;
                    data.loadingMFA = true;
                    await identity.verifyMFA(applicationId, data.token);
                    window.location.href = `${process.env.VUE_APP_IDENTITY_URL}redirect/continue/${applicationId}`;
                }
                catch (e)
                {
                    data.error = e.response.status;
                    data.loadingMFA = false;
                }
            }
        }

        async function reset ()
        {
            if (!data.loadingMFA)
            {
                try
                {
                    data.loadingMFA = true;
                    await identity.resetMFA(data.mfa.username);
                    data.mfaReset = true;
                }
                catch (e)
                {
                    data.error = e.response.status;
                }
                finally
                {
                    data.showReset = false;
                }
            }
        }

        onMounted(async () =>
        {
            try
            {
                data.mfa = (await identity.getMFA(applicationId)).data;
            }
            catch (e)
            {
                data.mfaError = true;
                return;
            }
        });

        return {...toRefs(data), verify, reset};
    }
});
